import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Table, Row, Attributes, Expander, InfoIcon, List, WarningIcon } from '../components';
import { deleteEntityCurl, deleteEntityJava, deleteEntityJavascript, deleteEntityNode, deleteEntityPerl, deleteEntityPython, deleteEntityPhp, deleteEntityRuby, deleteEntityGo, deleteEntityC, deleteEntityVB, deleteEntityGroovy, deleteEntityObjectiveC, deleteEntitySwift } from 'requestExamples/entities';
export const account_name = '{account_name}';
export const endpoints = [{
  param: 'PUT',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient',
  subtitle: 'Create client',
  link: '#create-a-client'
}, {
  param: 'POST',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient/{client_id}',
  subtitle: 'Edit client',
  link: '#edit-a-client'
}, {
  param: 'POST',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient/{client_id}',
  subtitle: 'Merge clients',
  link: '#merge-clients'
}, {
  param: '',
  value: ''
}, {
  param: '',
  value: ''
}, {
  param: 'GET',
  value: '/nodes/entity-definition/{account_id}/SmartVault.Accounting.Firm/SmartVault.Accounting.FirmClient?eprop=true',
  subtitle: 'Retrieve type qualifiers',
  link: '#retrieve-type-qualifiers'
}, {
  param: 'PUT',
  value: '/typequalifiers/type-qualifier',
  subtitle: 'Add type qualifier',
  link: '#add-type-qualifier'
}, {
  param: '',
  value: ''
}, {
  param: '',
  value: ''
}, {
  param: 'PUT',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient/{client_id}/SmartVault.Accounting.ClientToPrincipal',
  subtitle: 'Assign employee to client',
  link: '#assign-employee-to-client'
}, {
  param: 'DELETE',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient/{client_id}/SmartVault.Accounting.ClientToPrincipal/User:{employee_id}',
  subtitle: 'Remove assigned employee from client',
  link: '#remove-assigned-employee-from-client'
}];
export const createEntity = [{
  param: 'PUT',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient'
}];
export const updateEntity = [{
  param: 'POST',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient/{client_id}'
}];
export const mergeClients = [{
  param: 'POST',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient/{client_id}'
}];
export const getTypeQualifier = [{
  param: 'GET',
  value: '/nodes/entity-definition/{accout_id}/SmartVault.Accounting.Firm/SmartVault.Accounting.FirmClient?eprop=true'
}];
export const newTypeQualifier = [{
  param: 'PUT',
  value: '/typequalifiers/type-qualifier'
}];
export const assignEmployees = [{
  param: 'PUT',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient/{client_id}/SmartVault.Accounting.ClientToPrincipal'
}];
export const deleteAssignedEmployees = [{
  param: 'DELETE',
  value: '/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient/{client_id}/SmartVault.Accounting.ClientToPrincipal/User:{employee_id}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  account_name,
  endpoints,
  createEntity,
  updateEntity,
  mergeClients,
  getTypeQualifier,
  newTypeQualifier,
  assignEmployees,
  deleteAssignedEmployees,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 {...{
      "id": "clients"
    }}>{`Clients`}</h1>
    <Row mdxType="Row">
	<div>
		<p>
			SmartVault's API allows the <a href="#create-a-client">creation</a> of clients as entities for an account as
			well as the ability to <a href="#edit-a-client">modify</a> them at any time.
			<br />
			<br />
			You can also <a href="#merge-clients">merge</a> clients under certain circumstances but you <strong>
				can't
			</strong> delete them.
			<br />
		</p>
	</div>
	<Table title="Endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <br />
    <br />
    <hr style={{
      "width": "50%"
    }} />
    <br />
    <br />

    <h2 {...{
      "id": "create-a-client"
    }}>{`Create a client`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			There are no limitations based on the user permissions for entity creation, both employee and administrator
			level users can create clients.
			<br />
			You can create both clients as individual (person) or entity.
			<br />
			<br />
		</p>
	</div>
	<Table title="Create an employee" rows={createEntity} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account_id',
      type: 'string',
      description: "The account's alphanumerical identification."
    }]} mdxType="Attributes" />
    <InfoIcon mdxType="InfoIcon" /> You can find more info on how to retrieve the account's id <a href="/api/entities/retrieve-an-entity#the-account-id" target="_blank">
	here
    </a>.
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Expander title="body parameters" mdxType="Expander">
	<List values={[{
        title: 'entity_definition',
        value: 'Needs to be "SmartVault.Accounting.Client" when adding clients.'
      }, {
        title: 'type_qualifier',
        value: 'Values can be: benefit, corporate, estate, exempt, fiduciary, gift, nonResident, organizer, partnership, sCorporate or a custom value.'
      }, {
        title: 'names',
        value: 'Array of objects with a "name" key for the client\'s names in case of the client being an entity.\r\nArray of objects with "FirstName", "MiddleName" and "LastName" as keys for the person being added.'
      }, {
        title: 'email_addresses',
        value: 'Array of objects with a "address" key for the client\'s email addresses.'
      }, {
        title: 'phone_numbers',
        value: 'Array of objects with a "number" key for the client\'s phone numbers.'
      }, {
        title: 'client_salutation_override',
        value: 'Exclusive for persons type, refers to the new default value for client salutation. Used in email notifications, etc.'
      }, {
        title: 'end_of_fiscal_year',
        value: 'Integer value of the month corresponding to the end of the fiscal year.'
      }, {
        title: 'tags',
        value: 'Array of objects with a "value" key for the client\'s tags.'
      }, {
        title: 'preparer',
        value: 'ID of the assigned employee as preparer to the client.'
      }, {
        title: 'aliases',
        value: 'Optional object with "domain", "key" and "value" keys that works as tag/identity.\r\ne.g.: when creating an engagement you assign it an id to uniquely identify/lookup it later.'
      }, {
        title: 'client_id',
        value: 'Alphabetic representation of the client ID being created. Will have an alphanumeric version registered when the client is created'
      }]} mdxType="List" />
    </Expander>
    <br />
    <InfoIcon mdxType="InfoIcon" />
Get more info about all the type qualifiers available in the <a href="#retrieve-type-qualifiers">
	retrieve type qualifiers section
    </a>
.<br />
    <InfoIcon mdxType="InfoIcon" />
You can also add your own custom type qualifiers for client creation. More information about this <a href="#add-type-qualifier">
	here
    </a>
.
    <br />
    <br />
    <br />
    <p>{`Example of a request body for adding a new client (entity).`}</p>
    <Expander title="body request" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Value when creating clients as entities right below "type_qualifier" needs to be "organization".
{
   "entity": {
      "meta_data": {
         "entity_definition": "SmartVault.Accounting.Client"
      },
      "smart_vault": {
         "accounting": {
            "client": {
               "type_qualifier": "Corporate",
               "organization": {
                  "names": [
                     {
                        "name": "Client name"
                     },
                  ],
                  "email_addresses": [
                     {
                        "address": "test@email.smartvault.com"
                     }
                  ],
                  "phone_numbers": [
                     {
                        "number": "+1123456789"
                     }
                  ]
               },
               "end_of_fiscal_year":12,
               "tags": [
                  {
                     "value": "Tag1"
                  },
                  {
                     "value": "tag2"
                  }
               ],
               "aliases": [
                  {
                     "Domain": "DOMAIN",
                     "Type": "TYPE",
                     "Value": "VALUE"
                  }
               ],
               "preparer": "ZYyvnlgcL123hl_fUz_kpA",
               "client_id": "SmartVault Account"
            }
         }
      }
   },
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`Example of a request body for adding a new client (person).`}</p>
    <Expander title="body request" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`// Value when creating clients as persons right below "type_qualifier" needs to be "persons".
{
   "entity":{
      "meta_data":{
         "entity_definition":"SmartVault.Accounting.Client"
      },
      "smart_vault":{
         "accounting":{
            "client":{
               "type_qualifier":"Individual",
               "persons":[
                  {
                     "names":[
                        {
                           "FirstName":"First name",
                           "MiddleName":"Middle name",
                           "LastName":"Last name"
                        }
                     ],
                     "email_addresses":[
                        {
                           "address":"person@smartvault.com"
                        }
                     ],
                     "phone_numbers":[
                        {
                           "Number":"+1234567890"
                        }
                     ]
                  }
               ],
               "client_salutation_override":"Salutation override",
               "end_of_fiscal_year":12,
               "tags":[
                  {
                     "value":"tag1"
                  },
                  {
                     "value":"tag2"
                  }
               ],
               "aliases":[
                  {
                     "Domain":"domain.com",
                     "Type":"type",
                     "Value":"value"
                  }
               ],
               "preparer":"ZYyvnlgcL123hl_fUz_kpA",
               "client_id":"New Person ID"
            }
         }
      }
   }
}
`}</code></pre>
    </Expander>
    <br /><br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Will return the newly added client object.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "sbs7Og6gM0a2_ZB0FPxLxA",
        "createdOn": "2020-07-02T05:25:54-05:00",
        "modifiedOn": "2020-07-02T05:25:54-05:00",
        "uri": "/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w/SmartVault.Accounting.FirmClient/sbs7Og6gM0a2_ZB0FPxLxA",
        "dav_uri": "/nodes/pth/SmartVault Account/Clients/T-Z/Test name",
        "your_permissions": 113
    }
}
`}</code></pre>
    </Expander>
    <br />
    <p>{`You can get an error when trying to add a new client if there's already a client in that account with the same client ID.`}</p>
    <WarningIcon mdxType="WarningIcon" /> If you are setting the "aliases" field, this also would need to be exclusive to the new client,
otherwise it also prompt you with the following error.
    <br />
    <br />
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -1593835510,
        "error_text": "Object already exists.",
        "instance_guid": "7a64462f-923a-4c1a-96f5-a444cb7a7d05",
        "status_code": 500
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "edit-a-client"
    }}>{`Edit a client`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			You can modify every input parameter of the client after it has been created.
			<br />
		</p>
	</div>
	<Table title="Edit a client" rows={updateEntity} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account_id',
      type: 'string',
      description: "The account's alphanumerical identification."
    }, {
      name: 'client_id',
      type: 'string',
      description: "The client's alphanumerical identification."
    }]} mdxType="Attributes" />
    <InfoIcon mdxType="InfoIcon" /> You can find more info on how to retrieve the account's id <a href="/api/entities/retrieve-an-entity#the-account-id" target="_blank">
	here
    </a>.
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Expander title="body parameters" mdxType="Expander">
	<List values={[{
        title: 'entity_definition',
        value: 'Needs to be "SmartVault.Accounting.Client" when editing clients.'
      }, {
        title: 'type_qualifier',
        value: 'Values can be: benefit, corporate, estate, exempt, fiduciary, gift, nonResident, organizer, partnership, sCorporate or a custom value.'
      }, {
        title: 'names',
        value: 'Array of objects with a "name" key for the client\'s names.'
      }, {
        title: 'email_addresses',
        value: 'Array of objects with a "address" key for the client\'s email addresses.'
      }, {
        title: 'phone_numbers',
        value: 'Array of objects with a "number" key for the client\'s phone numbers.'
      }, {
        title: 'end_of_fiscal_year',
        value: 'Integer value of the month corresponding to the end of the fiscal year.'
      }, {
        title: 'tags',
        value: 'Array of objects with a "value" key for the client\'s tags.'
      }, {
        title: 'preparer',
        value: 'ID of the assigned employee as preparer to the client.'
      }, {
        title: 'aliases',
        value: 'Optional object with "domain", "key" and "value" keys that works as tag/identity.\r\ne.g.: when creating an engagement you assign it an id to uniquely identify/lookup it later.'
      }, {
        title: 'client_id',
        value: "Alphabetic representation of the client ID being created. Will have it's alphanumeric version registered when the client is created"
      }]} mdxType="List" />
    </Expander>
    <br />
    <br />
    <p>{`Example of a request body for updating the membership type an employee.`}</p>
    <Expander title="body request" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
   "query": false,
   "update": {
      "entity": {
         "meta_data": {
            "entity_definition": "SmartVault.Accounting.Client",
            "name": "Updated name"
         },
         "smart_vault": {
            "accounting": {
               "client": {
                  "organization": {
                     "names": [
                        {
                           "name": "Updated name"
                        }
                     ],
                     "email_addresses": [
                        {
                           "address": "updatedemail@smartvault.com"
                        }
                     ],
                     "phone_numbers": [
                        {
                           "Number": "+123456789"
                        }
                     ]
                  },
                  "type_qualifier": "Fiduciary",
                  "end_of_fiscal_year": 1,
                  "tags": [
                    {
                        "value": "updated tag"
                    }
                  ],
                  "preparer": "ZYyvnlgcL123hl_fUz_kpA",
                  "client_id": "Updated client ID"
               }
            }
         }
      }
   }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Will return the modified client.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "createdOn": "2020-07-02T05:44:24-05:00"
        "dav_uri": "/nodes/pth/SmartVault Account/Clients/T-Z/Name"
        "modifiedOn": "2020-07-02T05:44:24-05:00"
        "name": "kQaQUoOQ2k6G0mYQaJrMMw"
        "uri": "/nodes/entity/SmartVault.Accounting.Firm/dZz67AeDg0GnvBRoNiJ_5w/SmartVault.Accounting.FirmClient/kQaQUoOQ2k6G0mYQaJrMMw"
        "your_permissions": 113
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "merge-clients"
    }}>{`Merge clients`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			SmartVault allows the merge of two clients as long as this two share, at least one of the 2 following
			elements:
			<br />
			<br />
			<List values={[{
            title: "The client's tax identifier."
          }]} mdxType="List" />
			<List values={[{
            title: "The client's name."
          }]} mdxType="List" />
		</p>
	</div>
	<Table title="Merge clients" rows={mergeClients} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account_id',
      type: 'string',
      description: "The account's alphanumerical identification."
    }, {
      name: 'client_id',
      type: 'string',
      description: "The client's alphanumerical identification. This will be the client on which the merge will happen."
    }]} mdxType="Attributes" />
    <InfoIcon mdxType="InfoIcon" /> You can find more info on how to retrieve the account's id <a href="/api/entities/retrieve-an-entity#the-account-id" target="_blank">
	here
    </a>.
    <br />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <p>{`The only value that needs to be modified for every merge is the "value" in the "parameters" object.`}<br />{`
This needs to be the path to the client that merges into the client with client_id& specified in the path param.`}<br /><br /></p>
    <Expander title="body request" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
   "query":false,
   "verb": {
      "action": "merge",
      "parameters": [
         {
            "key": "with",
            "value": "/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmClient/{client_id}"
         }
      ]
   }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "retrieve-type-qualifiers"
    }}>{`Retrieve type qualifiers`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			All the type qualifiers related to a client can be retrieved by making a request to this section endpoint
			and looking in the response body for the "type_qualifiers" object that "applies_to" "organization".
			<br />
			<br />
			Check the example below for clarity.
		</p>
	</div>
	<Table title="Retrieve type qualifier" rows={getTypeQualifier} mdxType="Table" />
    </Row>
    <br />
    <br />
    <Expander title="example" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.FirmClient",
        "createdOn": "2020-07-06T15:51:19-05:00",
        "modifiedOn": "2020-07-06T15:51:19-05:00",
        "uri": "/nodes/entity-definition/{account_id}/SmartVault.Accounting.Firm/SmartVault.Accounting.FirmClient",
        "your_permissions": 64,
        "entityDefinitionExProperties": {
            "api_name": "SmartVault.Accounting.Client",
            "label": "Client",
            "plural_label": "Accounting Clients",
            "scope": "ACCOUNT",
            "type_qualifiers": [
                {
                    "applies_to": "organization",
                    "type_qualifiers": [
                        {
                            "value": "2177",
                            "string_identifier": "Testtypequalifierforentityb3bf4e6ab9c54f90838f5a8c9e6f2f3c",
                            "display": "Test type qualifier for entity"
                        },
                        {
                            "value": "2178",
                            "string_identifier": "Testtypequalifierforentity28102377bd8144780957b8bc0c70e61eb",
                            "display": "Test type qualifier for entity2"
                        },
                        {
                            "value": "2179",
                            "string_identifier": "Testtypequalifierforentity395b7e7ca7f1443758877ea90d0882b6b",
                            "display": "Test type qualifier for entity3"
                        },
                        {
                            "value": "1",
                            "string_identifier": "Partnership",
                            "display": "Partnership"
                        },
                        {
                            "value": "2",
                            "string_identifier": "Corporate",
                            "display": "Corporate"
                        },
                        {
                            "value": "3",
                            "string_identifier": "SCorporate",
                            "display": "S Corporate"
                        },
                        {
                            "value": "4",
                            "string_identifier": "Fiduciary",
                            "display": "Fiduciary"
                        },
                        {
                            "value": "5",
                            "string_identifier": "Exempt",
                            "display": "Exempt"
                        },
                        {
                            "value": "6",
                            "string_identifier": "Estate",
                            "display": "Estate"
                        },
                        {
                            "value": "7",
                            "string_identifier": "Gift",
                            "display": "Gift"
                        },
                        {
                            "value": "8",
                            "string_identifier": "Benefit",
                            "display": "Benefit"
                        },
                        {
                            "value": "9",
                            "string_identifier": "NonResident",
                            "display": "Non Resident"
                        },
                        {
                            "value": "10",
                            "string_identifier": "Organizer",
                            "display": "Organizer"
                        }
                    ],
                    "account_id": "h4bEql3mPESe5dwSiGfMVw"
                },
                {
                    "applies_to": "persons",
                    "type_qualifiers": [
                        {
                            "value": "1810",
                            "string_identifier": "Autoinsurance62459977fa244e2ca373788c5d3f11e7",
                            "display": "Auto insurance"
                        },
                        {
                            "value": "2180",
                            "string_identifier": "Autoinsurance2ca41ef76da224edfb5da3df4a5b93545",
                            "display": "Auto insurance 2"
                        },
                        {
                            "value": "0",
                            "string_identifier": "Individual",
                            "display": "Individual"
                        }
                    ],
                    "account_id": "h4bEql3mPESe5dwSiGfMVw"
                }
            ]
            ...
        }
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "add-type-qualifier"
    }}>{`Add type qualifier`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			You can create new custom type qualifiers for an account.
			<br /> These can be related either to an entity or a person, the types of client present in the SmartVault infrastructure.
			<br />
		</p>
	</div>
	<Table title="Add type qualifier" rows={newTypeQualifier} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <Expander title="Body parameters" mdxType="Expander">
	<List values={[{
        title: 'applies_to',
        value: 'The type of client that the type qualifier being added applies to. Can be either "organization" for entities or "persons" for person.'
      }, {
        title: 'display',
        value: 'The new type qualifier display name.'
      }, {
        title: 'account_id',
        value: 'The account ID where the default type qualifiers will be added.'
      }]} mdxType="List" />
    </Expander>
    <br />
    <br />
    <p>{`The difference for the requests to create a type qualifier for entity or person is the value you assign to the "applies_to" key in the request body.`}</p>
    <Row codeExamples mdxType="Row">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
   "items": [
      {
         "applies_to": "organization",
         "type_qualifiers": [
            {
               "display": "Test type qualifier for entity"
            }
         ],
         "account_id": "h4bEql3mPESe5dwSiGfMVw"
      }
   ]
}
`}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
   "items": [
      {
         "applies_to": "persons",
         "type_qualifiers": [
            {
               "display": "Test type qualifier for persons"
            }
         ],
         "account_id": "h4bEql3mPESe5dwSiGfMVw"
      }
   ]
}
`}</code></pre>
    </Row>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "assign-employee-to-client"
    }}>{`Assign employee to client`}</h2>
    <Row mdxType="Row">
	<div>
		<p>
			As specified{' '}
			<a target="_blank" href="/api/entities/employees#create-an-employee">
				here
			</a>
			, those employees created with he "assigned" type can only work with clients that they are assigned to.
			<br />
		</p>
	</div>
	<Table title="Assign employee to client" rows={assignEmployees} mdxType="Table" />
    </Row>
    <br />
    <br />
    <div>
	To retrieve the "client_id" of all the employee's with "assigned" type, perform a{' '}
	<span style={{
        "color": "#3c76e9"
      }}>GET</span> request to `/nodes/entity/SmartVault.Accounting.Firm/{account_name}
	/SmartVault.Accounting.FirmToEmployee?aemployees=true&children=1&eprop=true`.
    </div>
    <br />
This request will return something similar the following:
    <Expander title="assigned employees" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.FirmToEmployee",
        "createdOn": "2020-07-09T08:39:58-05:00",
        "modifiedOn": "2020-07-09T08:39:58-05:00",
        "uri": "/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee",
        "your_permissions": 32832,
        "total_children": 2,
        "children": [
            {
                "name": "BApBXaIlMEa70k2vRLg7Kw",
                "createdOn": "2020-07-09T08:39:58-05:00",
                "modifiedOn": "2020-07-09T08:39:58-05:00",
                "uri": "/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee/BApBXaIlMEa70k2vRLg7Kw",
                "dav_uri": "/nodes/pth/SmartVault Account/Employees/New Last Name, New First Name",
                "your_permissions": 113,
                "accountExProperties": {
                    "available_user_licenses": 0,
                    "available_diskspace_bytes": 536870912000,
                    "versionExProperties": {
                        "uuid": "h4bEql3mPESe5dwNoGfMVw",
                        "version": 8
                    },
                    "name": "SmartVault Account",
                    "available_seasonal_worker_licenses": 0,
                    "storage_location": "CLOUD",
                    "locale": 1,
                    "niche": 1,
                    "questionnaire": false,
                    "developer_account": false
                },
                "entityExProperties": {
                    "meta_data": {
                        "entity_definition": "SmartVault.Core.UserAssociation"
                    },
                    "smart_vault": {
                        "core": {
                            "user_association": {
                                "identity": {
                                    "Domain": "core.smartvault.com",
                                    "Type": "user",
                                    "Value": "iX1KpSTkOEOVq-PiAsQxpg"
                                },
                                "aliases": [
                                    {
                                        "Domain": "core.smartvault.com",
                                        "Type": "user",
                                        "Value": "iX1KpSTkOEOVq-PiAsQxpg"
                                    }
                                ],
                                "persons": [
                                    {
                                        "names": [
                                            {
                                                "FirstName": "New First Name",
                                                "LastName": "New Last Name"
                                            }
                                        ],
                                        "phone_numbers": [
                                            {
                                                "Number": "+1123456789"
                                            }
                                        ],
                                        "email_addresses": [
                                            {
                                                "address": "email3@smartvault.com"
                                            }
                                        ]
                                    }
                                ],
                                "administrator": false,
                                "seasonal_worker": false,
                                "can_set_seasonal_worker": false,
                                "can_clear_seasonal_worker": false,
                                "membership_type": "AssignedEmployee"
                            }
                        }
                    }
                }
            },
            {
                "name": "Z12WrlMf6k68im_NQ6DjFA",
                "createdOn": "2020-07-09T08:39:58-05:00",
                "modifiedOn": "2020-07-09T08:39:58-05:00",
                "uri": "/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee/Z12WrlMf6k68im_NQ6DjFA",
                "dav_uri": "/nodes/pth/SmartVault Account/Employees/test@test.es",
                "your_permissions": 113,
                "accountExProperties": {
                    "available_user_licenses": 0,
                    "available_diskspace_bytes": 536870912000,
                    "versionExProperties": {
                        "uuid": "h4bEql3mPESe5dwNoGfMVw",
                        "version": 8
                    },
                    "name": "SmartVault Account",
                    "available_seasonal_worker_licenses": 0,
                    "storage_location": "CLOUD",
                    "locale": 1,
                    "niche": 1,
                    "questionnaire": false,
                    "developer_account": false
                },
                "entityExProperties": {
                    "meta_data": {
                        "entity_definition": "SmartVault.Core.UserAssociation"
                    },
                    "smart_vault": {
                        "core": {
                            "user_association": {
                                "identity": {
                                    "Domain": "core.smartvault.com",
                                    "Type": "user",
                                    "Value": "nflP_fFCVkeKBWLUtnEC_A"
                                },
                                "aliases": [
                                    {
                                        "Domain": "core.smartvault.com",
                                        "Type": "user",
                                        "Value": "nflP_fFCVkeKBWLUtnEC_A"
                                    }
                                ],
                                "persons": [
                                    {
                                        "names": [
                                            {
                                                "FirstName": "Test",
                                                "LastName": "TestName"
                                            }
                                        ],
                                        "email_addresses": [
                                            {
                                                "address": "test@test.es"
                                            }
                                        ]
                                    }
                                ],
                                "administrator": false,
                                "seasonal_worker": false,
                                "can_set_seasonal_worker": false,
                                "can_clear_seasonal_worker": false,
                                "membership_type": "AssignedEmployee"
                            }
                        }
                    }
                }
            }
        ],
        "entityDefinitionExProperties": {
            "api_name": "SmartVault.Core.UserAssociation",
            "label": "Employee",
            "plural_label": "Employees",
            "scope": "ACCOUNT",
            "suggested_replacement_macros_property": {
                "items": [
                    {
                        "value": "replace:%LASTNAME%, %FIRSTNAME%",
                        "label": "Last name, First name (e.g. Smith, John)",
                        "display": true
                    }
                ]
            }
        }
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account_id',
      type: 'string',
      description: "The account's alphanumerical identification."
    }, {
      name: 'client_id',
      type: 'string',
      description: "The client's alphanumerical identification."
    }]} mdxType="Attributes" />
    <br />
    <br />
    <p><strong parentName="p">{`Body parameters`}</strong></p>
    <hr></hr>
    <p>{`While assigning an employee to a client, you can make it a "Firm manager" by specifying the following property in the request body.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`{
 "rid":"8",
 "member":true
}
`}</code></pre>
    <Expander title="body parameters" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
   "entity":{
      "meta_data":{
         "entity_definition":"SmartVault.Core.UserAssociation"
      },
      "smart_vault":{
         "core":{
            "user_association":{
               "identity":{
                  "Domain":"core.smartvault.com",
                  "Type":"user",
                  "Value":"nflP_fFCVkeKBWLUtnEC_A"
               },
               "aliases":[
                  {
                     "Domain":"core.smartvault.com",
                     "Type":"user",
                     "Value":"nflP_fFCVkeKBWLUtnEC_A"
                  }
               ],
               "persons":[
                  {
                     "names":[
                        {
                           "FirstName":"Activation",
                           "LastName":"pending"
                        }
                     ],
                     "email_addresses":[
                        {
                           "address":"test@test.es"
                        }
                     ]
                  }
               ],
               "administrator":false,
               "seasonal_worker":false,
               "can_set_seasonal_worker":false,
               "can_clear_seasonal_worker":false,
               "membership_type":"AssignedEmployee"
            }
         }
      }
   },
   "role_associations":[
      {
         "rid":9,
         "member":true
      },
      {
         "rid":"8",
         "member":true
      }
   ]
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <br />

    <h2 {...{
      "id": "remove-assigned-employee-from-client"
    }}>{`Remove assigned employee from client`}</h2>
    <Row mdxType="Row">
	<div>
		<p>You are also able to delete one of the already assigned employees to a client.</p>
	</div>
	<Table title="Delete assigned employee" rows={deleteAssignedEmployees} mdxType="Table" />
    </Row>
    <br />
    <br />
    <p><strong parentName="p">{`Parameters`}</strong></p>
    <hr></hr>
    <Attributes items={[{
      name: 'account_id',
      type: 'string',
      description: "The account's alphanumerical identification."
    }, {
      name: 'client_id',
      type: 'string',
      description: "The client's alphanumerical identification."
    }, {
      name: 'employee_id',
      type: 'string',
      description: "The assigned employee's id."
    }]} mdxType="Attributes" />
    <br />
    <br />
    <div>
	You can perform a <span style={{
        "color": "#3c76e9"
      }}>GET</span> request to
    </div>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`/nodes/entity/SmartVault.Accounting.Firm/{ account_id }/SmartVault.Accounting.FirmClient/{ client_id }/SmartVault.Accounting.ClientToPrincipal;
`}</code></pre>
    <p>{`to retrieve all the information related to the assigned employees to that client.`}<br /><br /></p>
    <div>
	You can then search for the assigned user id in the "identity" object in the "user_association" and use it to delete
	that assigned user. Check the response example below for the <span style={{
        "color": "#3c76e9"
      }}>GET</span> request.
    </div>
    <br />
    <Expander mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "SmartVault.Accounting.FirmToEmployee",
        "createdOn": "2020-07-09T08:50:57-05:00",
        "modifiedOn": "2020-07-09T08:50:57-05:00",
        "uri": "/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee",
        "your_permissions": 32832,
        "total_children": 2,
        "children": [
            {
                "name": "BApBXaIlMEa70k2vRLg7Kw",
                "createdOn": "2020-07-09T08:50:57-05:00",
                "modifiedOn": "2020-07-09T08:50:57-05:00",
                "uri": "/nodes/entity/SmartVault.Accounting.Firm/{account_id}/SmartVault.Accounting.FirmToEmployee/BApBXaIlMEa70k2vRLg7Kw",
                "dav_uri": "/nodes/pth/SmartVault Account/Employees/New Last Name, New First Name",
                "your_permissions": 113,
                "accountExProperties": {
                    "available_user_licenses": 0,
                    "available_diskspace_bytes": 536870912000,
                    "versionExProperties": {
                        "uuid": "h4bEql3mPESe5dwNoGfMVw",
                        "version": 8
                    },
                    "name": "SmartVault Account",
                    "available_seasonal_worker_licenses": 0,
                    "storage_location": "CLOUD",
                    "locale": 1,
                    "niche": 1,
                    "questionnaire": false,
                    "developer_account": false
                },
                "entityExProperties": {
                    "meta_data": {
                        "entity_definition": "SmartVault.Core.UserAssociation"
                    },
                    "smart_vault": {
                        "core": {
                            "user_association": {
                                "identity": {
                                    "Domain": "core.smartvault.com",
                                    "Type": "user",
                                    "Value": "iX1KpSTkOEOVq-PiAsQxpg"  <<<
                                },
                                ...
                            }
                        }
                    }
                }
            }
        ]
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      